import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useAuthUser } from "@frontegg/nextjs";
import { getOperationRoute, routes } from "holocene-constants/routes";
import { useUserDetails } from "holocene-providers/common";
import { OperationType } from "holocene-utils/types";
import { useGetCurrentUserInfo } from "holocene-hooks/users.hooks";
import { FeaturePermissionEnum } from "holocene-services/user.service/types";
import { useActiveOperation } from "holocene-hooks/utils.hooks";

const Home: NextPage = () => {
  const user = useAuthUser();
  const { operationType } = useUserDetails();
  const { data: userInfo } = useGetCurrentUserInfo();
  const activeOperation = useActiveOperation();

  const pageToLoad = (() => {
    if (!userInfo) return "";
    const assignedPermissions = userInfo?.detailInfo.UserAssignedFeaturePermissions ?? [];
    const permissionMap = assignedPermissions.reduce(
      (obj: Record<string, boolean>, permissionVal) => {
        return {
          ...obj,
          [permissionVal.FeaturePermission.feature]: true,
        };
      },
      {}
    );
    const typeOfOperation =
      activeOperation === OperationType.PURCHASE ? OperationType.PURCHASE : OperationType.SALES;
    if (permissionMap[FeaturePermissionEnum.CONTROL_TOWER]) {
      return getOperationRoute("controlTower", typeOfOperation);
    }
    if (permissionMap[FeaturePermissionEnum.PERFORMANCE]) {
      return getOperationRoute("performanceDashboard", typeOfOperation);
    }
    if (permissionMap[FeaturePermissionEnum.DELIVERY]) {
      return getOperationRoute("deliveryQueue", typeOfOperation);
    }
    if (permissionMap[FeaturePermissionEnum.DISPATCH]) {
      return getOperationRoute("deliveryDispatched", typeOfOperation);
    }
    if (permissionMap[FeaturePermissionEnum.TRADE_LANES]) {
      return getOperationRoute("tradeLaneV3", typeOfOperation);
    }
    if (permissionMap[FeaturePermissionEnum.SETTINGS]) {
      return getOperationRoute("settings", typeOfOperation);
    }
    return routes.settings;
  })();

  const router = useRouter();

  if (user?.accessToken) {
    localStorage.setItem("userInfo", JSON.stringify({ accessToken: user?.accessToken }));
    if (pageToLoad) {
      router.push(pageToLoad);
    }
  }
  return null;
};

export default Home;
